// const jwt = require("jsonwebtoken")
import config from "../config";
import { ILoginResponse } from "../interfaces/responses";


export default class AuthService {

    // static instance = AuthService.instance || new AuthService();
    private static instance: AuthService;

    private constructor(){ }

    public static getInstance(): AuthService {
        if (!this.instance){
            this.instance = new AuthService();
        }

        return this.instance;
    }

    async login(email: string, password: string) {
        try {

            console.log(config)
            let res = await fetch(`${config.SERVER_URL}/api/auth/employee-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email, password: password })
            });
    
            let resJson: ILoginResponse = await res.json();
        
            console.log(resJson.token)

            if (resJson.token){
                localStorage.setItem("token", resJson.token);
                return true;
            }
            else
                return false;
    
        } catch (err){
            console.log(err);
            return false
        }
    }

    getLoggedInUser(){

        const token = localStorage.getItem("token");

        if (!token){
            return null;
        }

        // return jwt.decode(token);
        return token;

    }

}