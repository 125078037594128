import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import LoginForm from "../../components/login/LoginForm";
import AuthService from "../../services/auth.service";


const Login: React.FC = () => {

  return (
    <IonPage>
      <IonContent fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login!</IonTitle>
          </IonToolbar>
        </IonHeader> */}

        <LoginForm />

      </IonContent>
    </IonPage>
  );
};
  
export default Login;