import { 
    IonAccordion, 
    IonAccordionGroup, 
    IonContent, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonIcon
} from "@ionic/react"
import { useEffect, useState } from "react";
import moment from "moment";
import EmployeeService from "../../services/employee.service";
import WeeklySchedule from "../../components/weeklySchedule/WeeklySchedule";
import { useHistory } from 'react-router-dom';
import "./Dashboard.css";
 
const Dasbhoard: React.FC = () => {

    const employeeService = EmployeeService.getInstance();
    const history = useHistory();


    const logOut = () => {
        localStorage.removeItem("token");
        history.push("/login")
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonLabel  slot="end">
                        <a onClick={logOut} className="logout-btn">LOGOUT</a>
                    </IonLabel>
                </IonToolbar>
            </IonHeader>
            <IonContent>
        
                <WeeklySchedule />
            </IonContent>
        </IonPage>
    )
}

export default Dasbhoard;