

const config: any =  {};


if (process.env.CUSTOM_NODE_ENV === 'development'){
    config.SERVER_URL = "http://localhost:8080"
}
else {
    config.SERVER_URL = "https://portal.aevis.de"
}

export default config;