import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/login/Login';

import "./config";
import Dasbhoard from './pages/dashboard/Dashboard';
import ProtectedRoute from './helpers/ProtectedRoute';

import authService from './services/auth.service';
import EventComments from './pages/dashboard/event/EventComments';

setupIonicReact();

const App: React.FC = () => {

  const originalFetch = window.fetch;
  window.fetch = async (url, opts) => {
  
    const token = authService.getInstance().getLoggedInUser();
  
    // opts: any = opts ? opts : {};
    if (!opts){
      opts = {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    }
    else {
      if (!opts.headers){
        opts.headers = {
          'authorization': `Bearer ${token}`
        }
      }
      else {
        opts.headers = {
          ...opts.headers,
          authorization: `Bearer ${token}`
        }
      }
    }
  
    // opts.headers['authorization'] = `Bearer ${token}`
    // if (opts.headers){
    //   // opts.headers.authorization = `Bearer ${token}`
    //   opts.headers
    // }
    // else {
    //   opts.headers = {
    //     'authorization': `Bearer ${token}`
    //   }
    // }
  
    const response = await originalFetch(url, opts);
  
    return response;
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          {/* <Route exact path="/dashboard">
            <Dasbhoard />
          </Route> */}
          <ProtectedRoute exact path="/dashboard">
            <Dasbhoard />
          </ProtectedRoute>
          <ProtectedRoute exact path="/event/:event_id">
            <EventComments />
          </ProtectedRoute>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
