import { IonButton, IonImg, useIonRouter } from "@ionic/react";
import { useState } from "react";
import AuthService from "../../services/auth.service";

import "./LoginForm.css"

const LoginForm: React.FC = () => {

    const authService: AuthService = AuthService.getInstance();

    const router = useIonRouter();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const attemptLogin = async () => {

        const success = await authService.login(
            email.trim(),
            password.trim()
        );

        if (success){
            router.push("/dashboard")
        }

    }

    return (
        <div className="Login-form">
            <div className="m-auto">
                <IonImg src="assets/icon/icon_bkp.png"></IonImg>
                <input type="text" value={email} placeholder="Email" onChange={evt => setEmail(evt.target.value)}/>
                <br />
                <input type="password" 
                    value={password}
                    placeholder="Password"
                    onChange={evt => setPassword(evt.target.value)}/>
                <br />
                <IonButton className="login-btn" onClick={attemptLogin}>Submit</IonButton>
            </div>
        </div>
    );
  };
  
export default LoginForm;