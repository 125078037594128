import { useEffect, useState } from "react";

import config from "../../config";

import "./EmployeeComment.css";

const EmployeeComment: React.FC<any> = ({ text, images }) => {

    
    

    const [currImageIndex, setCurrImageIndex] = useState(images.length > 0 ? 0 : -1);

    const incrementIndex = (incr: number) => {

        if (images.length === 0)
            return

        const newInd = Math.abs((currImageIndex + incr) % images.length);

        setCurrImageIndex(newInd);

    }

    useEffect(() => {

    }, []);

    return (
        <div className="Comment-container">
            <p>{text}</p>
            {
                images && images.length > 0 ?
                    <div className="Comment-image-container">
                        <div 
                            className="Back-btn"
                            onClick={() => incrementIndex(-1)}>{"Prev"}</div>
                        <img src={currImageIndex >= 0 ? `${config.SERVER_URL}/${images[currImageIndex]}` : "#"}/>
                        <div 
                            className="Forward-btn"
                            onClick={() => incrementIndex(1)}>{"Next"}</div>
                    </div>
                    : null

            }
        </div>
    )
}

export default EmployeeComment;