import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

export default class PhotoService {

    private static instance: PhotoService;

    private constructor(){}

    public static getInstance(){
        if (!PhotoService.instance)
            PhotoService.instance = new PhotoService();

        return PhotoService.instance
    }

    async takePhoto(){
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Camera,
            quality: 100
        });

        return photo;
    }

}