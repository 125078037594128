import moment from "moment";
import { useEffect, useState } from "react";
import EmployeeService from "../../services/employee.service";
import RRule, { rrulestr } from "rrule";
import SingleEvent from "./SingleEvent";
import { IScheduleEntry } from "../../interfaces/responses";
import { 
    IonAccordionGroup, 
    IonGrid, 
    IonRow, 
    IonCol,
    IonIcon,
} from "@ionic/react";

import {
    arrowBackOutline,
    arrowForwardOutline
} from "ionicons/icons"

import "./WeeklySchedule.css";

interface IGroupedEvents {
    Monday: IScheduleEntry[];
    Tuesday: IScheduleEntry[];
    Wednesday: IScheduleEntry[];
    Thursday: IScheduleEntry[];
    Friday: IScheduleEntry[];
    Saturday: IScheduleEntry[];
    Sunday: IScheduleEntry[];
}

const WeeklySchedule: React.FC = () => {

    const employeeService = EmployeeService.getInstance();

    const [weeklyOffset, setWeeklyOffset] = useState(0)

    const [startOfWeek, setStartOfWeek] = useState(moment().startOf('W').toDate());

    // const [headerText, setHeaderText] = useState("");

    useEffect(() => {
        fetchWeeklySchedule();
        // setHeaderText(formatHeader());
    }, [weeklyOffset])


    const [groupedEvents, setGroupedEvents] = useState<IGroupedEvents>({
        Monday: [], 
        Tuesday: [], 
        Wednesday: [], 
        Thursday: [], 
        Friday: [],
        Saturday: [],
        Sunday: []
    });

    const fetchWeeklySchedule = async () => {

        let d = moment(startOfWeek).add(weeklyOffset * 7, 'd');

        // let dateFrom = d.toDate().toISOString();
        // let dateTo = d.clone().add(5, 'd').endOf('d').toDate().toISOString();

        const res = await employeeService.getWeeklyScheduleOfCurrentEmployee(
            d.toDate()
        );
    
        let groups: IGroupedEvents & Record<string, Array<any>> = { 
            "Monday": [], 
            "Tuesday": [], 
            "Wednesday": [], 
            "Thursday": [], 
            "Friday": [],
            "Saturday": [],
            "Sunday": []
        }

        for (let schedule of res){
            let dayIndex: number;
            if (schedule.events.length > 0){
                dayIndex = (new Date(
                    schedule.events[0].date
                )).getDay() - 1
            }
            else {
                // rrule lib indexes monday as 0, 
                // but date class as 1

                const rr: RRule = rrulestr(schedule.rrule);

                dayIndex = schedule.type !== "Single" ? 
                    rrulestr(schedule.rrule).options.byweekday[0] 
                    : rr.options.dtstart.getDay() - 1;
               
            }

            console.log(schedule)
            console.log(dayIndex)

            if (dayIndex !== null && dayIndex !== undefined){
                console.log(dayIndex);
                let dayStr: string = Object.keys(groups)[dayIndex];
                console.log("Inserting into", dayStr)
                groups[dayStr].push(schedule)
            }

        }

        console.log(groups)

        setGroupedEvents(groups as IGroupedEvents);

    }

    const formatHeader = () => {
        switch (weeklyOffset){
            case 0:
                return "This week"
                break;
            case 1:
                return "Next week";
                break;
            case -1:
                return "Last week";
                break;
            default:
                let start = moment(startOfWeek).add(weeklyOffset * 7, 'd').format("DD. MMM");
                // let end = moment(start).clone().add(5, 'd').endOf('d').format("DD. MMM");
                let end = moment(startOfWeek).add(weeklyOffset * 7 + 5, 'd').format("DD. MMM");
                // console.log(start, "-", end)
                return `${start} - ${end}`
        }
    }




    return (
        <>

            <IonGrid className="Week-picker">
                <IonRow>
                    <IonCol size="2">
                        <IonIcon 
                            className="Icon"
                            color="white" 
                            icon={arrowBackOutline}
                            onClick={() => setWeeklyOffset(weeklyOffset - 1)}></IonIcon>

                    </IonCol>
                    <IonCol size="8">
                        <h3>{formatHeader()}</h3>
                    </IonCol>
                    <IonCol size="2">
                        <IonIcon 
                            className="Icon"
                            color="white" 
                            icon={arrowForwardOutline}
                            onClick={() => setWeeklyOffset(weeklyOffset + 1)}></IonIcon>
                    </IonCol>
                </IonRow>
            </IonGrid>

            <IonAccordionGroup className="Event-list-item">
                {
                    Object.entries(groupedEvents).map(([day, list]) => {
                        return list.map((schedule: any) => <SingleEvent schedule={schedule} dayFullName={day} key={day + "_" + schedule._id}/>)
                    })
                }
            </IonAccordionGroup>


        </>
    );
}

export default WeeklySchedule;