import { Route, Redirect, RouteProps } from "react-router-dom";
import AuthService from "../services/auth.service";


const ProtectedRoute: React.FC<RouteProps> = ({children, ...rest}) => {

    const authService = AuthService.getInstance();

    return (
        <Route
            {...rest}
            render={ ( { location } ) => 
                authService.getLoggedInUser() ? (children) : 
                    (<Redirect to="/login" />)
                }/>
    )

}

export default ProtectedRoute;