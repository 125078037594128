import { 
    IonAccordion, 
    IonButton, 
    IonItem, 
    IonLabel, 
    IonList,
    IonCheckbox,
    useIonRouter
} from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { IScheduleEntry, IScheduleEvent } from "../../interfaces/responses";
import EventService from "../../services/event.service";


// const SingleEvent: React.FC<any> = ({ schedule, dayFullName }) => {
const SingleEvent: React.FC<any> = (props) => {

    const schedule: IScheduleEntry = props.schedule as IScheduleEntry;
    const dayFullName = props.dayFullName;

    const company_id = schedule.company._id;
    const schedule_id: any = schedule._id;

    // let schedule: IScheduleEntry = schedule as IScheduleEntry;
    const eventService: EventService = EventService.getInstance();

    const router = useIonRouter();
    
    // console.log("Creating single event!")
    // console.log(schedule)

    // console.log("Initial...", initialViewedEvent)

    const initialViewedEvent = schedule && schedule.events && schedule.events.length > 0 ?
        schedule.events[0]
        : undefined;

    const [viewedEvent, setViewedEvent]: [IScheduleEvent | undefined, any] = useState(initialViewedEvent);

    useEffect(() => {


        setViewedEvent(initialViewedEvent)
    }, [schedule])

    const isTaskComleted = (task: string) => {

        if (!viewedEvent)
            return false;

        
        return viewedEvent['tasks'][task];
    }

    const isEventToday = () => {

        if (!viewedEvent)
            return false;

        // console.log(viewedEvent)

        return moment(viewedEvent.date).diff(moment(), 'd') === 0;

    }

    const updateTask = async (task: string, value: boolean) => {

        if (!viewedEvent)
            return

        const newViewedEvent = await eventService.updateEventTask(
            company_id,
            schedule_id,
            viewedEvent._id,
            task,
            value
        )

        setViewedEvent(newViewedEvent);

    }

    const listTasks = () => {
        return viewedEvent ?
            (
                viewedEvent.tasks ? 
                    Object.keys(viewedEvent.tasks).map((task: string) => {
                        return (
                            <IonItem key={`${schedule._id}_${task}`}>
                                <IonLabel>
                                    {task}
                                </IonLabel>
                                <IonCheckbox
                                    key={`checkbox_${schedule._id}_${task}`}
                                    disabled={!isEventToday()}
                                    checked={isTaskComleted(task)}
                                    onClick={(evt: any) => {
                                        // console.log("CLick!")
                                        // console.log(evt)
                                        updateTask(task, evt.target.checked)
                                    }}/>
                            </IonItem>
                        )
                    })
                    : null
            )
            : (
                schedule.tasks.map((task: string) => {
                    return (
                        <IonItem key={`${schedule._id}_${task}`}>
                            <IonLabel>
                                {task}
                            </IonLabel>
                            <IonCheckbox
                                key={`checkbox_${schedule._id}_${task}`}
                                disabled={true}
                                checked={false}/>
                        </IonItem>
                    )
                })
            )
    }

    return (
        <IonAccordion>
            <IonItem slot="header">
                <IonLabel>
                    {`${dayFullName}, ${schedule.timeFrom}-${schedule.timeTo}`}
                    {/* {viewedEvent ? viewedEvent._id : dayFullName} */}
                </IonLabel>
            </IonItem>

            <IonList slot="content">
                { listTasks() }

                {
                    viewedEvent ?
                        <IonButton
                            expand="full"
                            className="btn-white"
                            onClick={() => router.push(`/event/${viewedEvent._id}?company_id=${company_id}`)}>
                            View Comments
                        </IonButton>
                        : null
                }
            </IonList>
        </IonAccordion>
    )

}

export default SingleEvent;