import config from "../config";
import moment from "moment";


export default class EmployeeService {

    private static instance: EmployeeService;

    public static getInstance(): EmployeeService {
        if (!EmployeeService.instance){
            EmployeeService.instance = new EmployeeService();
        }

        return EmployeeService.instance;
    }

    async getWeeklyScheduleOfCurrentEmployee(weekday: Date){

        const url = new URL(`/api/employee/my-weekly-schedule?date=${moment(weekday).format('YYYY-MM-DD')}`, config.SERVER_URL);

        const res = await fetch(url.toString())

        return await res.json();

        // return [
        //     {
        //         "_id": "6276ac0766c7beb1a174d44a",
        //         "dateStart": "2022-05-11T00:00:00.000Z",
        //         "timeFrom": "08:00",
        //         "timeTo": "09:00",
        //         "type": "Single",
        //         "rrule": "DTSTART:20220511T000000Z\nRRULE:FREQ=YEARLY;COUNT=1",
        //         "approved": true,
        //         "tasks": [],
        //         "company": "6276abe566c7beb1a174d3e8",
        //         "employee": {
        //             "_id": "6276abe566c7beb1a174d401",
        //             "first_name": "Radnik",
        //             "last_name": "Jedan",
        //             "email": "radnik1@gmail.com",
        //             "password": "$2b$10$GwiiKpqSQkobom/l88zIru87pgQ1.f8Vax7nn7.z4gp5QH53KSW86",
        //             "role": "employee",
        //             "__v": 0,
        //             "createdAt": "2022-05-07T17:27:01.309Z",
        //             "updatedAt": "2022-05-07T17:27:01.309Z"
        //         },
        //         "createdAt": "2022-05-07T17:27:35.286Z",
        //         "updatedAt": "2022-05-07T17:27:35.286Z",
        //         "__v": 0,
        //         "events": []
        //     },
        //     {
        //         "_id": "6276ac0766c7beb1a174d44a",
        //         "dateStart": "2022-05-11T00:00:00.000Z",
        //         "timeFrom": "08:00",
        //         "timeTo": "09:00",
        //         "type": "Single",
        //         "rrule": "DTSTART:20220511T000000Z\nRRULE:FREQ=YEARLY;COUNT=1",
        //         "approved": true,
        //         "tasks": [],
        //         "company": "6276abe566c7beb1a174d3e8",
        //         "employee": {
        //             "_id": "6276abe566c7beb1a174d401",
        //             "first_name": "Radnik",
        //             "last_name": "Jedan",
        //             "email": "radnik1@gmail.com",
        //             "password": "$2b$10$GwiiKpqSQkobom/l88zIru87pgQ1.f8Vax7nn7.z4gp5QH53KSW86",
        //             "role": "employee",
        //             "__v": 0,
        //             "createdAt": "2022-05-07T17:27:01.309Z",
        //             "updatedAt": "2022-05-07T17:27:01.309Z"
        //         },
        //         "createdAt": "2022-05-07T17:27:35.286Z",
        //         "updatedAt": "2022-05-07T17:27:35.286Z",
        //         "__v": 0,
        //         "events": []
        //     }
        // ]
        
    }

}