import config from "../config";
import { IEmployeeComment } from "../interfaces/responses";

export default class EventService {

    // private static 

    private static instance: EventService;

    private constructor() {}
    
    public static getInstance(): EventService{
        if (!EventService.instance){
            EventService.instance = new EventService();
        }

        return EventService.instance;
    }

    async getEventById(eventId: string) {
        const url = new URL(this.constructEventUrlPath(undefined, undefined, eventId), config.SERVER_URL);

        const res = await fetch(url.href);

        if (res.status !== 200)
            throw new Error("Could not get event!")

        return await res.json();

    }

    async submitEventComment(companyId: string, scheduleId: string, eventId: string, comment: IEmployeeComment) {

        const path = this.constructEventUrlPath(companyId, scheduleId, eventId) + "/comment";
        const url = new URL(path, config.SERVER_URL);

        const body = {
            text: comment.text,
            images: comment.images
        }

        let res = await fetch(url.href, {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(body)
        });

        if (res.status !== 200){
            throw new Error("Unable to submit comment!");
        }

        return true;

    }

    async updateEventTask(companyId: string, scheduleEntryId: string, eventId: string, task: string, value: boolean){
        
        const url = new URL(`/api/company/${companyId}/schedule/${scheduleEntryId}/event/${eventId}`, config.SERVER_URL);

        const body = {
            tasks: {
                [task]: value
            }
        }

        let res = await fetch(url.href, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (res.status !== 200)
            throw new Error("Unable to update event!");

        return await res.json();

    }

    constructEventUrlPath(companyId: string | undefined, scheduleId: string | undefined, eventId: string){

        return `/api/company/${companyId}/schedule/${scheduleId}/event/${eventId}`

    }

}