import { 
    IonHeader, 
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
    IonButton,
    IonFooter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import EmployeeComment from "../../../components/employeeComment/EmployeeComment";
import { IEmployeeComment, IScheduleEvent } from "../../../interfaces/responses";
import EventService from "../../../services/event.service";
import PhotoService from "../../../services/photo.service";


const EventComments: React.FC = () => {

    const eventService = EventService.getInstance();
    const photoService = PhotoService.getInstance();

    const { event_id } : any = useParams();
    const searchParams = new URLSearchParams(useLocation().search);
    
    const [event, setEvent] : [IScheduleEvent | undefined , any] = useState<IScheduleEvent>();

    const emptyForm: IEmployeeComment = {
        text: "",
        images: []
    };

    // interface ICommentForm {
    //     text: string,
    //     images: string[]
    // }

    const [newComment, setNewComment] : [IEmployeeComment | undefined, any] = useState<IEmployeeComment>();

    const resetForm = async () => {
        setNewComment(emptyForm);
    }

    const loadEvent = async () => {

        const evt = await eventService.getEventById(event_id);

        setEvent(evt);

    }

    useEffect(() => {
        loadEvent();
    }, []);

    const openCamera = async () => {

        const photo = await photoService.takePhoto();
        console.log(photo)

        // const photo64 = `data:file/${photo.dataUrl};${photo.base64String}`

        // NOTE: Only one photo supported
        setNewComment({
            text: newComment?.text,
            images: [photo.dataUrl]
        });

        console.log("Photo taken!");
        console.log(photo);
    }

    const updateForm = async (field: string, value: string | undefined | null) => {
        console.log(field, value);
        
        if (value === null || value === undefined){
            return
        }
        
        setNewComment({text: value})
        
        // const comment = newComment;

        // if (!comment)
        //     return;

        // if (field === 'text'){
        //     comment.text = value;
        // }
        // else {
        //     comment.images.push(value);
        // }

    }

    const uploadComment = async () => {

        console.log(event)

        if (!newComment)
            return
        const scheduleId = event?.scheduleEntry as string;
        const companyId = searchParams.get('company_id') as string;

        await eventService.submitEventComment(companyId, scheduleId, event_id, newComment);

        loadEvent();
        resetForm();

    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/dashboard"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList className="comment-list">
                    <IonItem>
                        <IonLabel position="stacked">
                            Comment Text
                        </IonLabel>
                        <IonTextarea 
                            className="input"
                            value={newComment?.text || ""} 
                            placeholder="" 
                            onIonChange={(evt) => { updateForm('text', evt.detail.value) }}/>
                    </IonItem>

                    <IonItem>
                        <IonButton className="btn-white" onClick={openCamera}>
                            Take picture!     
                        </IonButton>
                    </IonItem>
                </IonList>

                <IonList className="comment-list">
                    {
                        event && (event as IScheduleEvent).employee_comments ?
                            (event as IScheduleEvent).employee_comments.map((comment: IEmployeeComment, ind: number) => {
                                return (
                                    <IonItem key={ind}>
                                        <EmployeeComment text={comment.text} images={comment.images}/>
                                    </IonItem>
                                )
                            })
                            : null
                    }
                </IonList>
            </IonContent>
            <IonFooter >
                    <IonToolbar>
                        <IonButton className="btn-white" expand="full"  onClick={uploadComment}>
                                Submit comment
                        </IonButton>
                    </IonToolbar>
            </IonFooter>
        </IonPage>
    )

}

export default EventComments;